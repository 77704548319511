import {polyfill as autoPolyfillPromise} from 'es6-promise';
import 'jspolyfill-array.prototype.find';
import 'url-search-params-polyfill';
import 'es7-object-polyfill';
import {detect} from 'detect-browser';

autoPolyfillPromise();

function insertUnsupportedBrowserWarningIfNeeded() {
    try {
        var browser = detect();

        // Check if the current browser is Internet Explorer
        if (browser && browser.name === 'ie') {
            var parent = document.body;

            var refChild = parent.hasChildNodes() ? parent.childNodes[0] : null;
            var unsupportedBrowserWarning = document.createElement('div');

            unsupportedBrowserWarning.style.cssText =
                'height: 60px; background: #fffce0; padding: 20px;';

            unsupportedBrowserWarning.innerHTML =
                'This web browser is not supported.' +
                ' Please use one of supported browsers: ' +
                '<a href="https://www.google.com/chrome/" ' +
                'title="Google Chrome" target="_blank" ' +
                'rel="noreferrer">Google Chrome</a>, ' +
                '<a href="https://www.microsoft.com/edge" ' +
                'title="Microsoft Edge" target="_blank" ' +
                'rel="noreferrer">Microsoft Edge</a>, ' +
                '<a href="https://www.mozilla.org/firefox/new/" ' +
                'title="Mozilla Firefox" target="_blank" ' +
                'rel="noreferrer">Mozilla Firefox</a>.';
            if (parent.insertBefore && refChild) {
                parent.insertBefore(unsupportedBrowserWarning, refChild);
            } else {
                parent.appendChild(unsupportedBrowserWarning);
            }
        }
    } catch (e) {
        console.error(
            'Got an exception while trying to render unsupported browser notice',
            e
        );
    }
}

/**
 * This is a workaround against f5/webedge bug well-known as "Invalid Cookie Name" exception:
 *  The very first request from DSS Portal or embedded SDK is sent to the backend without any cookies.
 *  Something ahead webEdge and CWS services injects empty cookie header,
 *  which causes runtime java.lang.IllegalArgumentException: "Empty cookie header string"
 *  References:
 *    - source code: https://github.va.opower.it/web-auth/web-edge-service/ +
 *                      web-edge-server/src/main/java/com/opower/web/edge/jwt/OucssCustomerRetriever.java#L204
 *    - example correlationId: 537b43c6-c19d-496e-886a-66ae556ecd5c
 *    - Tickets: https://ticket.opower.com/browse/AEE-6618
 *  Since the bug is in the ancient and obsolete code, the repository has been moved to attic
 *  years ago - it is almost impossible to prove that the problem is not in us, either fix
 *  buggy code. So when we find no solution to a problem - it's probably not a problem
 *  to be solved, but rather a truth to be accepted.
 *  We install some random cookie domain-wide for 1 year. It means absolutely nothing. It's purpose
 *  to bypass buggy webEdge/infra services.
 */
function invalidCookieWorkaround() {
    const cname = 'dssPortalCW';
    const cvalue = '1';
    const exdays = 360;

    const d = new Date();

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1e3);
    const expires = `expires=${d.toUTCString()}`;

    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function start() {
    insertUnsupportedBrowserWarningIfNeeded();
    invalidCookieWorkaround();

    import('./bootstrap.js')
        .then(function(module) {
            module.default();
        })
        .catch(function(e) {
            console.error('Application Bootstrap Error: ', e);
            console.error('-- System halted.');
        });
}

/**
 * Since the bundle `script` is added to `head` - document maybe is not available yet, and we had to render on
 * DomContentLoaded event.
 * Unfortunately current implementation embeds bundle into head, so we had to use DomContentLoaded event to start
 * react app or display unsupported warning message.
 * Once this will be fixed in x-web-maestro - any `DomContentLoaded` event listeners can be removed.
 *
 * @see {@link https://github.va.opower.it/roman-danilov/x-web-maestro/blob/\
 *        WPT-6961-standalone-serving-stub/lib/middleware/serve-standalone.js#L95|serve-standalone}
 * @see {@link https://stackoverflow.com/questions/26566317/\
 *        invariant-violation-registercomponent-target-container-is-not-a-dom-elem/26566330#26566330|Dan Abramov post}
 */
(function() {
    if (/complete|interactive/.test(document.readyState)) {
        start();
    } else {
        document.addEventListener('DOMContentLoaded', start);
    }
})();
